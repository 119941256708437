import Header from "../components/Header"
import nft from "../media/nft.png"
import gif from "../media/mint2.gif"
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "./Mint.css"
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions"
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"
import { BsHandIndexThumb } from "react-icons/bs"
import { ADDRESS } from "../redux/contractInfo/contract"
import { ethers } from "ethers"
import { Web3Provider } from "@ethersproject/providers"
import abi from "../redux/contractInfo/abi.json"
import { HashLoader } from "react-spinners";
import loadingimg from "../media/loading.PNG"
import MerkleTree from "merkletreejs";
import keccak256 from "keccak256";
import leggendary from "../redux/contractInfo/leggendary.json"
import fcfsList from "../redux/contractInfo/fcfs.json"
import { Buffer } from 'buffer';
import { ToastContainer, toast } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

const Mint = () => {
    // @ts-ignore
    window.Buffer = Buffer;

    const dispatch = useDispatch()
    const blockchain = useSelector((state) => state)
    const [amount, setAmount] = useState(1)
    const [userNFTs, setNFTs] = useState([])
    const [hiddenURI, setHidden] = useState("")
    const [loading, setLoading] = useState(false)
    const [currentSlide, setSlide] = useState(0)
    const [guaranteedWLState, setGuaranteedState] = useState(false)
    const [FCFSWLState, setFCFSState] = useState(false)
    const [publicState, setPublicState] = useState(false)

    const revealRef = useRef(null)


    const decrement = () => {
        if (amount > 1) setAmount(amount - 1)
    }

    const increment = () => {
        setAmount(amount + 1)
    }

    const reveal = async () => {
        const provider = new Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const contract = new ethers.Contract(ADDRESS, abi, signer);

        await contract.reveal(userNFTs[currentSlide].tokenID)
        fetchInfo().then(() => setLoading(false))
    }

    const mint = async () => {
        const provider = new Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const contract = new ethers.Contract(ADDRESS, abi, signer);

        let currentCost = await contract.publicPrice()
        let max = await contract.maxPublic()
        let publicBalance = await contract.publicBalance(blockchain.account)

        if (amount > Number(max.toString()) - Number(publicBalance.toString())) {
            toast("Exceeds the max of " + max.toString() + " NFTs", {
                type: "error"
            })
            setLoading(false)
            return
        }

        try{
            let pmint = await contract.publicMint(amount, { value: String(Number(currentCost.toString()) * amount) })
            let ptx = await pmint.getTransaction()
            await ptx.wait()
        }
        catch(e){
            toast(e.message.substring(0, e.message.indexOf("(")), {
                type: "error"
            })
        }

        setAmount(1)
        fetchInfo().then(() => {
            fetchNFTs().then(() => setLoading(false))
        })
        setLoading(false)
    }

    const leggendaryMint = async () => {
        const provider = new Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const contract = new ethers.Contract(ADDRESS, abi, signer);

        let currentCost = await contract.guaranteedWLPrice()
        let max = await contract.maxGuaranteed()
        let guaranteedWLBalance = await contract.guaranteedWLBalance(blockchain.account)
        let guaranteedFreeBalance = await contract.guaranteedFreeBalance()

        let costToPay = "0";
        if(Number(guaranteedWLBalance.toString()) >= Number(guaranteedFreeBalance.toString())){
            costToPay = String(Number(currentCost.toString()) * amount)
        }
        else{
            let currentlyOwned = Number(guaranteedWLBalance.toString()) + amount;
            if (currentlyOwned > Number(guaranteedFreeBalance.toString())) {
                let toBePaid = currentlyOwned - Number(guaranteedFreeBalance.toString());
                costToPay = String(Number(currentCost.toString()) * toBePaid)
            }
        }

        if (amount > Number(max.toString()) - Number(guaranteedWLBalance.toString())) {
            toast("Exceeds the max of " + max.toString() + " NFTs", {
                type: "error"
            })
            setLoading(false)
            return
        }

        const leggendaryLeafNodes = leggendary.map(addr => keccak256(addr));
        const leggendaryTree = new MerkleTree(leggendaryLeafNodes, keccak256, { sortPairs: true })
        const leggendaryUser = keccak256(blockchain.account)
        const leggendaryProof = leggendaryTree.getHexProof(leggendaryUser)

        try{
            let lmint = await contract.guaranteedMint(amount, leggendaryProof, { value: costToPay })
            let ltx = await lmint.getTransaction()
            await ltx.wait()
        }
        catch(e){
            toast(e.message.substring(0, e.message.indexOf("(")), {
                type: "error"
            })
        }

        setAmount(1)
        fetchInfo().then(() => {
            fetchNFTs().then(() => setLoading(false))
        })
    }

    const fcfsMint = async () => {
        const provider = new Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const contract = new ethers.Contract(ADDRESS, abi, signer);

        let currentCost = await contract.FCFSPrice()
        let max = await contract.maxFCFS()
        let FCFSBalance = await contract.FCFSBalance(blockchain.account)

        if (amount > Number(max.toString()) - Number(FCFSBalance.toString())) {
            toast("Exceeds the max of " + max.toString() + " NFTs", {
                type: "error"
            })
            setLoading(false)
            return
        }

        const FcfsLeafNodes = fcfsList.map(addr => keccak256(addr));
        const FcfsTree = new MerkleTree(FcfsLeafNodes, keccak256, { sortPairs: true })
        const fcfsUser = keccak256(blockchain.account)
        const fcfsProof = FcfsTree.getHexProof(fcfsUser)

        try{
            let fmint = await contract.FCFSMint(amount, fcfsProof, { value: String(Number(currentCost.toString()) * amount) })
            let ftx = await fmint.getTransaction()
            await ftx.wait()
        }
        catch(e){
            console.log(e)
        }

        setAmount(1)
        fetchInfo().then(() => {
            fetchNFTs().then(() => setLoading(false))
        })
    }

    const fetchInfo = async () => {
        try{
            const provider = new Web3Provider(window.ethereum)
            const signer = provider.getSigner();
            const contract = new ethers.Contract(ADDRESS, abi, signer);

            let guaranteedWL = await contract.guaranteedWL()
            let fcfs = await contract.FCFS()
            //console.log(blockchain.account)
            let hidden = await contract.hiddenMetadataUrl()

            const user = keccak256(blockchain.account)

            const leggendaryLeafNodes = leggendary.map(addr => keccak256(addr));
            const leggendaryTree = new MerkleTree(leggendaryLeafNodes, keccak256, { sortPairs: true })
            const leggendaryProof = leggendaryTree.getHexProof(user)
            const leggendaryVerify = leggendaryTree.verify(leggendaryProof, user, leggendaryTree.getHexRoot())

            const FcfsLeafNodes = fcfsList.map(addr => keccak256(addr));
            const FcfsTree = new MerkleTree(FcfsLeafNodes, keccak256, { sortPairs: true })
            const fcfsProof = FcfsTree.getHexProof(user)
            const fcfsVerify = leggendaryTree.verify(fcfsProof, user, FcfsTree.getHexRoot())

            setHidden(hidden)
            setGuaranteedState(guaranteedWL && leggendaryVerify)
            setFCFSState(fcfs && fcfsVerify)
            setPublicState(!guaranteedWL && !fcfs)
        }
        catch(e){
            toast("Try again later..", {
                type: "error"
            })
        }
    }

    const fetchNFTs = async () => {
        try{
            const provider = new Web3Provider(window.ethereum)
            const signer = provider.getSigner();
            const contract = new ethers.Contract(ADDRESS, abi, signer);

            let wallet = await contract.walletOfOwner(blockchain.account)
            let parsedNFTs = []
            for (let i = 0; i < wallet.length; i++) {
                let tokenID = Number(wallet[i].toString())
                let uri = await contract.tokenURI(tokenID)
                parsedNFTs.push({
                    tokenID,
                    uri
                })
            }
            setNFTs([...parsedNFTs])
        }
        catch(e){
            toast("Try again later..", {
                type: "error"
            })
        }
    }

    useEffect(() => {
        if (blockchain.account != null) {
            fetchInfo().then(() => {
                fetchNFTs().then(() => setLoading(false))
            })
        }
    }, [blockchain.account])

    useEffect(() => {
        if (blockchain.account && !loading) setLoading(true)
    }, [blockchain.account])

    useEffect(() => {
        document.title = 'Minting Dapp';
    }, []);

    useEffect(() => {
        if (blockchain.account != null) {
            revealRef.current.setAttribute("data-disabled", userNFTs[currentSlide].uri != hiddenURI)
        }
    }, [currentSlide, revealRef.current])

    return (
        <div className="w-full min-h-screen px-10 pb-20 flex flex-col items-center relative overflow-hidden">
            <Header />
            <ToastContainer
                position="top-right"
                autoClose={2000}
                newestOnTop={true}
                pauseOnHover
                limit={3}
                theme="dark"
            />

            <div className="mt-20 w-full max-w-[1216px] flex items-center justify-between flex-col sp2:flex-row">
                <div className="max-w-[600px]">
                    <h1 className="text-5xl text-white font-semibold text-center sp2:text-left">
                        Mint your DPRSSD
                    </h1>
                    <p className="text-white mt-5 text-center sp2:text-left">
                        DPRSSD is a heartfelt NFT project created to support you through tough times. We understand the depths of sadness, depression, and emotional struggles we all face. DPRSSD offers a safe space where NFT collectors can openly express their feelings, knowing they'll be heard with empathy and care.
                    </p>

                    {
                        blockchain.account != null && (publicState || guaranteedWLState || FCFSWLState) &&
                        <div className="flex items-center gap-5 mt-7 ml-2 justify-center sp2:justify-start">
                            <AiOutlineMinus color="#FFF" size={30} className="mt-0 cursor-pointer" onClick={decrement} />
                            <p className="text-xl text-white font-semibold">{amount}</p>
                            <AiOutlinePlus color="#FFF" size={30} className="mt-0 cursor-pointer" onClick={increment} />
                        </div>
                    }

                    <div className="grid grid-cols-2 gap-5 mt-10 w-full">
                        {
                            blockchain.account == null &&
                            <MintButton
                                mint={() => {
                                    setLoading(true)
                                    dispatch(connect())
                                }}
                                text={"CONNECT"}
                            />
                        }

                        {
                            publicState &&
                            <MintButton
                                mint={() => {
                                    setLoading(true)
                                    mint()
                                }}
                                text={"PUBLIC"}
                            />
                        }

                        {
                            blockchain.account != null && guaranteedWLState &&
                            <MintButton
                                mint={() => {
                                    setLoading(true)
                                    leggendaryMint()
                                }}
                                text="LEGENDARY WL"
                            />
                        }

                        {
                            blockchain.account != null && FCFSWLState &&
                            <MintButton
                                mint={() => {
                                    setLoading(true)
                                    fcfsMint()
                                }}
                                text="FCFS WL"
                            />
                        }

                        {
                            false &&
                            <MintButton
                                mint={async () => {
                                    const provider = new Web3Provider(window.ethereum)
                                    const signer = provider.getSigner();
                                    const contract = new ethers.Contract(ADDRESS, abi, signer);

                                    /*let ciao = await contract.getCost(blockchain.account, 4)
                                    console.log(ciao.toString())*/
                                }}
                                text="O CAZZ"
                            />
                        }
                    </div>
                </div>

                <div className="relative w-fit h-fit mt-10 sp2:mt-0">
                    <img src={gif} className="w-[450px]" loading="lazy" />
                </div>
            </div>

            {
                userNFTs.length != 0 && false &&
                <div className="mt-[200px] w-full max-w-[1216px] flex flex-col items-center">
                    <h1 className="text-white text-6xl font-semibold text-center">REVEAL YOUR NFTS</h1>
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        slidesPerView={1}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        modules={[EffectCoverflow]}
                        className="mySwiper"
                        onSlideChange={(swiper) => {
                            setSlide(swiper.realIndex)
                        }}
                    >
                        {
                            userNFTs.map((el, index) => (
                                <SwiperSlide className="w-fit" key={index}>
                                    <NFT tokenURI={el.uri} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                    {
                        userNFTs.length > 1 &&
                        <div className="mt-2 flex items-center gap-3">
                            <p className="text-lg text-white">Swipe to see your NFTs</p>
                            <BsHandIndexThumb size={17} className="mt-[-3px] hand" color="#FFF" />
                        </div>
                    }

                    <div
                        className="rounded-full py-2 px-8 text-black font-semibold bg-white data-[disabled=true]:opacity-50 data-[disabled=true]:cursor-not-allowed w-fit text-xl cursor-pointer mt-10"
                        onClick={(el) => {
                            if (el.currentTarget.getAttribute("data-disabled") == "false") {
                                setLoading(true)
                                //reveal()
                            }
                        }}
                        data-disabled={false}
                        ref={revealRef}
                    >
                        REVEAL
                    </div>
                </div>
            }

            <Loading appear={loading} />
        </div>
    )
}

const NFT = ({
    tokenURI = ""
}) => {

    /*useEffect(() =>{
        if(tokenURI != "REPLACE" && tokenURI != "") console.log(tokenURI)
    }, [tokenURI])*/

    return (
        tokenURI != "" ?
            <img src={nft} />
            :
            <img src={loadingimg} />
    )
}

const Loading = ({
    appear = false,
    forceCol = false
}) => {
    return (
        appear &&
        <div className={"fixed top-0 left-0 w-full bg-opacity-30 bg-[#dfe3e7] backdrop-blur-lg z-50 flex items-center justify-center " + (forceCol ? "flex-col h-full" : "flex-col sm:flex-row min-h-screen")}>
            <HashLoader color="#000" size={150} />
            <h1 className="font-bold text-8xl text-black ml-5">DPRSSD</h1>
        </div>
    )
}

const MintButton = ({
    text = "",
    mint = () => { },
}) => {
    return (
        <div
            className="col-span-1 rounded-full py-2 px-8 text-black font-semibold bg-white w-full xs:text-xl cursor-pointer flex justify-center"
            onClick={mint}
        >
            {text}
        </div>
    )
}

export default Mint