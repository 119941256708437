import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Mint from "./pages/Mint";

function App() {
  return (
    <div className="w-full min-h-screen">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to={"/home"} />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/mint" element={<Mint />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
